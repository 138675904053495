#error404 {
   background-color: transparent;
   align-items: center;
   display: flex;
   justify-content: center;
   height: 100vh;
   flex-direction: column;
}

#error404 > img {
   width: 150px;
}

#error404 > p {
   font-size: 24px;
}
