:root {
	--primary-color: #00263e;
	--secondary-color: #00b9b2;
}

@font-face {
	font-family: "Sansation Bold";
	src: url("../../fonts/sansation/Sansation_Bold.ttf");
	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: "Sansation";
	src: url("../../fonts/sansation/Sansation_Light.ttf");
	font-style: normal;
	font-weight: 300;
}

* {
	font-family: "Sansation", sans-serif;
	box-sizing: border-box;
}

::-webkit-scrollbar {
	width: 4px;
	height: 5px;
}

::-webkit-scrollbar-thumb {
	background: linear-gradient(13deg, #cccccc 14%, #cccccc 64%);
	border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
	background: linear-gradient(13deg, #999 14%, #999 64%);
}

::-webkit-scrollbar-track {
	background: #ffffff;
	border-radius: 10px;
	box-shadow: inset 7px 10px 12px #f0f0f0;
}

body,
#root {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #fafafa;
}

button {
	border: 2px solid var(--primary-color);
	border-radius: 8px;
	padding: 3px 20px;
	font-size: 0.95rem;
	color: white;
	background-color: var(--primary-color);
	cursor: pointer;
	transition: all 0.3s ease-out;
	height: auto;
	min-height: 40px;
}

#auth-page {
	justify-content: center;
	height: 100vh;
	align-items: center;
	display: flex;
	background-color: transparent;
	width: 380px;
	margin: auto;
	position: relative;
	z-index: 1;
}

@media (min-width: 1366px) {
	main {
		display: flex;
		margin: 0 auto;
		width: 1200px;
		background-color: white;
		flex-direction: column;
	}
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Firefox < 16 */
@-moz-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Internet Explorer */
@-ms-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Opera < 12.1 */
@-o-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
