.dropzone {
   flex: 1;
   display: flex;
   flex-direction: column;
   align-items: center;
   padding: 20px;
   border-width: 2px;
   border-radius: 8px;
   border-color: #eeeeee;
   border-style: dashed;
   background-color: #fafafa;
   color: #bdbdbd;
   outline: none;
   margin-bottom: 15px;
   transition: all 0.3s ease-out;
}

.dropzone:hover {
   background-color: white;
   color: black;
   cursor: move;
}

aside {
   display: flex;
   flex-flow: row wrap;
   margin-top: 16px;
}

aside > div {
   display: inline-flex;
   border-radius: 2px;
   border: 1px solid rgb(234, 234, 234);
   margin-bottom: 8px;
   margin-right: 8px;
   width: auto;
   height: 160px;
   padding: 4px;
   box-sizing: border-box;
}

aside > div img {
   display: block;
   width: auto;
   height: 100%;
}

aside > div > div {
   position: relative;
}
aside > div > div > .close {
   position: absolute;
   font-size: 20px;
   font-weight: 900;
   color: white;
   box-shadow: rgba(0, 0, 0, 0.1);
   right: 8px;
   cursor: pointer;
}
