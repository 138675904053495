#list {
	height: calc(100vh - 71px);
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-top: 0;
}

#list > .empty {
	justify-content: center;
	align-items: center;
	display: flex;
	height: calc(100vh - 71px);
	background-color: #f1f3f4;
}

#list > .empty > span {
	font-size: 22px;
	color: #333;
}

#list h1 {
	font-weight: 300;
	font-size: 21px;
	padding: 0 30px;
}

#list table {
	width: calc(100% - 60px);
	margin: 0 30px;
	border-collapse: collapse;
	box-shadow: #dbdbdb 0px 2px 5px;
}

#list table .right {
	text-align: right;
	width: 35px;
	cursor: pointer;
}

#list table tr {
	background-color: #fafafa;
}

#list table tr:nth-of-type(even) {
	background-color: #ffe8e5;
	background-color: white;
}

#list table td {
	padding: 12px;
	font-size: 15px;
	color: rgb(51, 51, 51);
	font-weight: 400;
	text-align: left;
}

#list table td svg {
	fill: rgb(51, 51, 51) !important;
	padding-top: 3px;
	font-weight: 100 !important;
}

#list table td[title="Eliminar"] svg {
	fill: #ff452b !important;
}

#list table td[title="Vista previa"] svg {
	fill: #3483f7 !important;
}
