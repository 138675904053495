aside#admin {
	flex: 1;
	background-color: #f0f0f5;
	margin-top: 0;
}

aside#admin .after {
	display: none;
}

aside#admin > span {
	display: none;
}

aside#admin ul {
	padding-left: 20px;
	width: 100%;
}

aside#admin ul span {
	color: var(--primary-color);
	letter-spacing: 2px;
	font-size: 14px;
	display: block;
	margin-bottom: 15px;
	margin-top: 25px;
	font-family: "Sansation Bold", sans-serif;
}

aside#admin ul a {
	color: rgb(51, 51, 51);
	font-size: 15px;
	text-decoration: none;
	display: block;
	margin: 10px 0 19px;
	font-weight: 400;
}

aside#admin ul a.active {
	color: var(--primary-color);
}

@media screen and (max-width: 1500px) {
	aside#admin {
		flex: 0;
	}

	aside#admin.showMenu {
		flex: 1;
		padding: 0;
	}

	aside#admin.showMenu ul {
		margin-top: 5px;
		width: auto;
		min-width: 226px;
	}

	aside#admin ul span,
	aside#admin ul a {
		display: none;
	}

	aside#admin.showMenu ul span,
	aside#admin.showMenu ul a {
		display: block;
	}

	aside#admin .after {
		display: block;
		margin: 10px 10px 0;
		cursor: pointer;
	}

	aside#admin > span {
		transform: rotate(90deg);
		position: absolute;
		top: 104px;
		left: -2px;
		display: block;
	}
}
