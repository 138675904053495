#page {
	justify-content: center;
	height: 100vh;
	align-items: center;
	display: flex;
	background-color: transparent;
	width: 380px;
	margin: auto;
	position: relative;
	z-index: 1;
}

#page form {
	width: 350px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: white;
	border-radius: 8px;
	padding: 10px 20px 5px;
	box-shadow: #dbdbdb 0px 2px 5px;
}

#page form h1 {
	font-size: 24px;
	margin-top: 10px;
}

#page form > div {
	width: 100%;
}

#page form input {
	padding-left: 13px !important;
}

#page form .button_wrapper {
	flex-direction: column;
	margin-top: 5px;
}

#page form .button_wrapper > div {
	flex-direction: row;
	display: flex;
	gap: 0 12px;
}

#page form button {
	width: 100%;
	height: auto;
	min-height: 20px;
	margin-top: 10px;
	margin-bottom: 12px;
}

#page form button.inverse {
	color: var(--primary-color);
	background-color: transparent;
	border-width: 1px;
	margin-bottom: 5px;
	margin-top: 0px;
}

#page form button.inverse.true {
	background-color: #ffe8e5;
}
