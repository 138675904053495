header {
	background: var(--primary-color);
	padding: 20px 30px;
	height: auto;
	box-shadow: 0 0 0;
	display: flex;
	justify-content: space-between;
}

header > div > h1 {
	font-size: 1.45rem;
	margin: -3.5px 0;
	padding-left: 1.2rem;
	color: var(--secondary-color);
	cursor: pointer;
}

header > div {
	padding: 2px 0 0 0;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	font-size: 16px;
}

header > div > div {
	padding-left: 12px;
	font-weight: 300;
}

header .rounded {
	width: 28px;
	height: 28px;
	position: relative;
	top: -3px;
	color: white;
	background: var(--secondary-color);
	font-weight: 400;
	border-radius: 50px;
	padding-top: 4px;
	font-size: 16px;
	padding-left: 8px;
	margin-left: 10px;
}

header .logout {
	color: white;
	font-size: 25px;
	font-weight: bold;
	position: relative;
	top: -5px;
	margin-left: 4px;
}

header .username,
header .links {
	padding-top: 2px;
	font-size: 17px;
	color: white;
}

header .links {
	padding-right: 20px;
	color: var(--secondary-color);
	font-size: 17px;
	font-family: "Sansation Bold";
}

header button {
	padding: 0 12px;
	min-height: 25px;
	font-size: 12px;
	border-radius: 5px;
}
