#auth-page {
	width: 100%;
	background: var(--secondary-color);
}

header.auth {
	background: var(--primary-color);
	padding: 18px 60px;
	height: auto;
	box-shadow: 0 0 0;
}

header.auth img {
	width: 240px;
	height: auto;
}

#auth-page main {
	width: 400px;
	background-color: transparent;
}

#auth-page form {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	background-color: rgba(231, 231, 231, 0.4);
	border-radius: 20px;
	padding: 10px 30px 30px;
	flex-direction: column;
	transition: all 0.3s ease;
}

#auth-page form:hover {
	transform: scale(1.02);
}

#auth-page form h1 {
	font-family: "Sansation Bold", sans-serif;
	color: var(--primary-color);
	font-size: 33px;
	margin-bottom: 10px;
}

#auth-page form input {
	border: 1px solid white;
	outline: 0;
	padding: 13px 13px;
	font-size: 16px;
	font-weight: 300;
	color: black;
	position: relative;
	height: 40px;
	margin-bottom: 0.3rem;
	width: 100%;
	border-radius: 10px;
}

#auth-page form input.error {
	border-color: #ff0000;
}

#auth-page form label {
	padding-bottom: 7px;
	font-size: 17px;
	padding-top: 8px;
	color: var(--primary-color);
	display: block;
}

#auth-page form .button_wrapper {
	margin-top: 10px;
}

#auth-page form .errorMessage {
	color: #ff0000;
	font-size: 15px;
	padding-top: 2px;
}

#auth-page form button {
	background-color: var(--primary-color);
	border-color: var(--primary-color);
	border-radius: 10px;
	font-size: 1.3rem;
	padding: 0.625rem 1.25rem;
	width: 100%;
}

@media (max-width: 500px) {
	.login-page {
		background-color: white;
	}
	.bg {
		display: none;
	}
	#auth-page form {
		box-shadow: 0 0 0;
	}
}
