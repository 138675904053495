#admin {
	height: calc(100vh - 54px);
	width: 100%;
	display: flex;
	flex-direction: row;
	align-content: flex-start;
}

#admin section {
	display: flex;
	flex-direction: column;
	flex: 6;
}

#admin section > .empty {
	justify-content: center;
	align-items: center;
	display: flex;
	height: 100vh;
	background-color: #f1f3f4;
}

#admin section > .empty > span {
	font-size: 22px;
	color: #333;
}

#admin h1 {
	font-weight: 600;
	font-size: 28px;
	padding: 5px 30px 8px;
	margin-bottom: 14px;
}

#admin section > div {
	display: flex;
	width: calc(100% - 60px);
	margin: 0 auto 20px;
	justify-content: space-between;
}

#admin section > div > div {
	display: flex;
	flex-direction: row-reverse;
}

#admin section > div > input {
	border: 0.5px solid #e9e9e9;
	border-radius: 8px;
	outline: 0;
	padding: 13px 13px;
	font-size: 14px;
	font-weight: 300;
	color: black;
	position: relative;
	height: 37px;
	width: 350px;
}

#admin table {
	width: calc(100% - 60px);
	margin: 0 30px;
	border-collapse: collapse;
	box-shadow: 0 5px 5px -5px rgb(0 0 0 / 30%);
}

#admin table .right {
	text-align: right;
	width: 35px;
	cursor: pointer;
}

#admin thead tr {
	background-color: var(--primary-color) !important;
}

#admin table tr {
	background-color: #fafafa;
}

#admin table tr:nth-of-type(even) {
	background-color: #ffe8e5;
	background-color: white;
}

#admin table td,
#admin table th {
	padding: 8px !important;
	font-size: 13px;
	color: rgb(51, 51, 51);
	font-weight: 400;
	text-align: left;
}

#admin table th {
	color: white;
}

#admin table td svg {
	fill: rgb(51, 51, 51) !important;
	padding-top: 3px;
	font-weight: 100 !important;
}

#admin table td svg.eye {
	fill: rgb(52, 131, 247) !important;
}

#admin table td[title="Eliminar"] svg {
	fill: #ff452b !important;
}

#admin table td[title="Vista previa"] svg {
	fill: #3483f7 !important;
}

@media (max-width: 1650px) {
	#admin section {
		flex: 5;
	}
}

@media (max-width: 1450px) {
	#admin section {
		flex: 4;
	}
}
