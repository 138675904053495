.Mui-checked {
	color: var(--primary-color) !important;
}

.MuiSwitch-track {
	background-color: var(--secondary-color) !important;
}

.switch-label {
	font-size: 14px;
	margin-top: 10px;
	margin-left: 0px;
	font-family: "Sansation Bold", sans-serif;
}
