#create-template {
	margin-top: 85px;
	border-radius: 8px;
	box-shadow: #dbdbdb 0px 2px 5px;
	margin-bottom: 20px;
}

#create-template section {
	display: flex;
	gap: 0 15px;
	padding: 25px 23px;
}

#create-template section .left {
	flex: 2;
	border: 1px solid #3483f7;
	border-radius: 6px;
	padding-left: 20px;
	padding-bottom: 10px;
	padding-right: 20px;
}

#create-template section .left h3 {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

#create-template section .left h3 span:nth-child(2) {
	cursor: pointer;
	padding-top: 3px;
}

#create-template section .left > div {
	width: 100%;
	height: 100%;
	cursor: move;
	float: left;
}

#create-template section .left .change-name-wrapper {
	position: relative;
	justify-content: flex-start;
	display: flex;
	width: 300px;
}

#create-template section .left [name="change-name-input"] {
	border: 0.5px solid #e9e9e9;
	border-radius: 8px;
	outline: 0;
	padding: 13px 13px;
	font-size: 16px;
	font-weight: 300;
	color: black;
	position: relative;
	margin-bottom: 0.2rem;
	width: 100%;
	height: 34px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

#create-template section .left .change-name-button {
	border: 2px solid var(--primary-color);
	border-radius: 8px;
	padding: 3px 15px;
	font-size: 14px;
	color: white;
	background-color: var(--primary-color);
	cursor: pointer;
	-webkit-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
	min-height: 34px;
	height: 34px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	margin-top: 0;
}

#create-template section .left > div > div > div,
#create-template section .left .extra {
	border: 1px solid #3483f7;
	background-color: white;
	padding: 0.5rem 1rem 0.5rem 2.2rem;
	margin-right: 0;
	margin-bottom: 0.7rem;
	cursor: move;
	float: left;
	border-radius: 6px;
	width: 100%;
	position: relative;
}

#create-template section .left .extra {
	cursor: not-allowed;
	background-color: #ebebeb;
}

#create-template section .left > div > div > div > svg,
#create-template section .left .extra svg,
.dragged svg {
	position: absolute;
	top: 6px;
	left: 8px;
	cursor: pointer;
}

#create-template section .left .extra svg {
	top: 8px;
	left: 9px;
}

#create-template section .left > div > div > div > span,
.dragged span {
	float: right;
	color: var(--primary-color);
	font-weight: bold;
	cursor: pointer;
	font-size: 16px;
}

#create-template section .left .open svg {
	transform: rotate(180deg);
}

#create-template section .left .body {
	margin-top: 13px;
	margin-left: -20px;
}

#create-template section .center {
	display: flex;
	flex-direction: column;
}

#create-template section .center > * {
	margin: 50px 0px;
}

#create-template section .right {
	flex: 1;
	border: 1px solid var(--primary-color);
	border-radius: 6px;
	padding: 5px 20px;
}

#create-template section .right > div {
	border: 1px solid var(--primary-color);
	background-color: white;
	padding: 0.5rem 1rem;
	margin-right: 0.7rem;
	margin-bottom: 0.7rem;
	cursor: move;
	float: left;
	border-radius: 6px;
}

#create-template section .right > div.disabled {
	cursor: none;
	pointer-events: none;
	text-decoration: line-through red;
	opacity: 0.4 !important;
}

.dragged {
	border: 1px solid #3483f7;
	background-color: #f0f6ff;
	padding: 0.5rem 1rem 0.5rem 2.2rem;
	margin-right: 0.7rem;
	margin-bottom: 0.7rem;
	cursor: move;
	float: left;
	border-radius: 6px;
	width: calc(100% - 20px);
	position: relative;
}
