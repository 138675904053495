.closeSearch {
	position: relative;
	top: 2px;
	left: -188px;
	z-index: 2;
	font-size: 22px;
	transform: scale(1.2, 1);
	color: var(--primary-color);
	cursor: pointer;
}
