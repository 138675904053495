#project-page {
	margin-top: 45px;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
	padding: 0 5px 10px;
	box-shadow: #dbdbdb 0px 2px 5px;
	margin-bottom: 20px;
	border: 1px solid var(--primary-color);
}
