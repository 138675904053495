.modal {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1050;
	overflow: hidden;
	outline: 0;
	background-color: rgba(0, 0, 0, 0.4);
	display: flex;
}

.modal > div {
	min-width: 800px;
	background-color: white;
	width: auto;
	margin: auto;
	position: relative;
	z-index: 1080;
	border-radius: 5px;
	padding: 7px 15px 20px;
	display: flex;
	flex-direction: column;
}

.modal > div > span {
	text-align: right;
	display: block;
	cursor: pointer;
	font-size: 24px;
}

.modal > div > div {
	width: 100%;
}

.modal h1 {
	font-family: "Sansation Bold", sans-serif;
	font-size: 24px;
	padding: 0;
	margin-top: -17px;
	width: 100%;
}

.modal h4 {
	font-family: "Sansation Bold", sans-serif;
	font-size: 18px;
	padding: 0 0 5px;
	border-bottom: 0.5px solid var(--primary-color);
	margin-top: 10px;
	margin-bottom: 15px;
	width: 100%;
}

.modal form > div {
	flex-direction: row;
	display: flex;
	flex-basis: 100%;
	gap: 0 10px;
}

.modal form > div > div {
	flex: 1;
}

.modal input,
.modal select,
.modal textarea {
	border: 0.5px solid #e9e9e9;
	border-radius: 8px;
	width: 100%;
	outline: 0;
	padding: 0 15px;
	font-size: 14px;
	font-weight: 300;
	color: black;
	position: relative;
	height: 34px;
	margin: 5px 0;
}

.modal textarea {
	height: auto;
	min-height: 75px;
	padding-top: 7px;
}

.modal label {
	font-size: 13px;
	margin-top: 5px;
	display: block;
	font-family: "Sansation Bold", sans-serif;
}

.modal button[type="submit"] {
	background-color: var(--primary-color);
	border-color: var(--primary-color);
	width: 100%;
}

.modal .errorMessage {
	color: rgb(255, 69, 43);
	font-size: 14px;
	margin-bottom: 5px;
}

.modal .error {
	border-color: rgb(255, 69, 43);
	background-color: #fff8f8;
}

.modal form button {
	margin-top: 10px;
	width: auto;
	padding: 5px 50px;
	font-size: 18px;
	min-height: 38px;
}
