.swal2-popup {
	padding: 10px;
	padding-bottom: 15px;
}
.swal2-title {
	text-align: left !important;
	font-size: 16px;
	color: var(--primary-color);
}

.swal2-html-container {
	background: #f1f1f1;
	border-radius: 3px;
	padding: 11px 15px;
	margin: 10px 15px 5px;
	text-align: left;
	color: black;
	font-size: 15px;
	line-height: 25px;
}

.swal2-actions {
	margin: 0 12px;
	justify-content: flex-end !important;
}

.swal2-styled.swal2-confirm {
	background-color: var(--primary-color);
	outline: 0 !important;
	padding: 3px 13px !important;
	font-size: 14px;
	height: 36px;
	min-height: 20px;
	width: 100%;
	margin-top: 5px;
}

.swal2-styled.swal2-confirm:focus,
.swal2-styled.swal2-confirm:hover {
	box-shadow: 0 0 0;
	outline: 0 !important;
}

.swal2-timer-progress-bar {
	background-color: rgba(255, 69, 43, 0.5);
	height: 4px;
}
