.empty_table {
	justify-content: center !important;
	align-items: center;
	display: flex;
	height: calc(100vh - 150px);
	background-color: #f1f3f4;
}

.empty_table > div {
	font-size: 20px;
	color: #333;
}
