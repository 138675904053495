.landing {
	padding: 15px 30px;
}

.landing h1 {
	margin-top: 5px;
	margin-bottom: 8px;
	font-size: 28px;
}

.landing > div {
	display: flex;
	flex-direction: row;
}

.landing > div .left {
	flex: 2;
	padding-right: 30px;
}

.landing > div .right {
	flex: 1;
}

.landing > div h3 {
	margin: 0 0 5px;
	font-size: 17px;
	font-weight: 400;
}

.landing > div .right > div {
	padding: 0 !important;
}

.landing > div .right > div form button {
	margin-top: 5px;
	margin-bottom: 23px;
}

.landing > div .right > div form input {
	margin-top: 3px;
	font-size: 14px;
}

.landing h6 {
	margin: 10px 0 20px;
	font-weight: 600;
	font-size: 12px;
}

.landing table {
	margin-bottom: 15px;
	padding-top: 5px;
}

.landing .back {
	height: 26px;
	font-size: 12px;
	padding: 0 4px;
	min-height: 1px;
	text-align: right;
	margin-top: 15px;
}

.landing h2 {
	font-size: 20px;
	margin-bottom: 10px;
}

.landing p {
	font-weight: 400;
	color: rgb(51, 51, 51);
	font-size: 14px;
	line-height: 1.45rem;
	white-space: pre-wrap;
	overflow-wrap: break-word;
	margin-top: 10px;
	height: auto;
	max-height: 400px;
	overflow-y: auto;
	padding-right: 15px;
}

.landing ul {
	padding-left: 0;
	color: rgb(51, 51, 51);
}

.landing li {
	list-style: none;
	font-size: 15px;
	font-weight: 900;
}

.landing table {
	margin: 0 0 15px;
	border-collapse: collapse;
	padding-top: 0;
}

.landing tr {
	background-color: #fafafa;
	border-bottom: 2px solid white;
}

.landing tr:nth-of-type(even) {
	background-color: #ffe8e5;
	background-color: white;
}

.landing thead tr {
	background-color: var(--primary-color);
}

.landing td,
.landing th {
	text-align: center;
	padding: 8px;
	font-size: 14px;
	color: rgb(51, 51, 51);
	font-weight: 400;
	position: relative;
}

.landing th {
	color: white;
}
